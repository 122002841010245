import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { registerEmailOrPhoneNumber } from "../../../services/userService";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha"; 

const RequestOtpMobileNumber = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneCodeError, setPhoneCodeError] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const location = useLocation();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (!captchaValue) {
      errorToast("Please complete the reCAPTCHA verification.");
      return;
    }
    const button = document.getElementById("btnLogin");
    try {
      let obj = {
        phoneNumber: phoneNumber,
        phoneCode: phoneCode,
        loginType: "customer",
      };
      
      if (obj.phoneNumber !== "") {
        button.disabled = true;
        let response = await registerEmailOrPhoneNumber(obj);
        button.disabled = false;

        if (response?.success) {
          successToast(response?.message);
          let verifyOtpPath = "";
          if (location.pathname.startsWith("/customer/register/request-otp-phone-number")) {
            verifyOtpPath = "/customer/register/phone-number-verify-otp";
          } else {
            verifyOtpPath = "/verify-otp";
          }

          // Navigate to the appropriate path
          navigate(verifyOtpPath, { state: { userEmailOrPhone: obj.phoneCode + obj.phoneNumber, loginType: "customer" } });
        } else {
          errorToast(response?.message);
          button.disabled = false;
        }
      }
      else{
        setPhoneCodeError(true);
      }
    } catch (error) {
      errorToast(error?.message);
      button.disabled = false;
    }
  };
  const handleChange = (value) => {
    const stringValue = String(value);
    const parsedPhoneNumber = parsePhoneNumber(stringValue);
    if (parsedPhoneNumber) {
      setPhoneCode(`+${parsedPhoneNumber?.countryCallingCode}`);
      setPhoneNumber(parsedPhoneNumber?.nationalNumber);
      setValue("phone_number", stringValue);
      setPhoneCodeError(false);
    } else {
      setPhoneCode("");
      setPhoneNumber("");
      // setValue("phone_number", "");
    }
    if (value) {
      setValue("phone_number", value, { shouldValidate: true });
      setPhoneCodeError(false);
    }
    if (value === undefined) {
      setValue("phone_number", setPhoneCode, { shouldValidate: true });
      setPhoneCodeError(true);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2">
            <div className="card card-topup bg-outline-primary mt-6">
              <div className="d-flex col-12 col-lg-12 align-items-center py-3">
                <div className="w-px-400 mx-auto">
                  <div className="card-body email-details" style={{ display: "block" }}>
                    <h3 className="mb-5 text-center">Register to continue</h3>
                    <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-3">
                        <label htmlFor="userEmailOrPhone" className="form-label">
                          Mobile Number
                        </label>
                        <Controller
                          name="phone_number"
                          control={control}
                          rules={{
                            required: "Required",
                          }}
                          render={({ field }) => (
                            <>
                              <PhoneInput
                                className="form-control rounded-pill"
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="AF"
                                value={field.value}
                                onChange={(value) => {
                                  handleChange(value);
                                }}
                              />
                              {phoneCodeError && <small className="text-danger">required</small>}
                              {errors?.phone_number && <small className="text-danger">{errors.phone_number.message}</small>}
                            </>
                          )}
                        />
                      </div>
                      <div className="mb-3">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} 
                          onChange={(value) => setCaptchaValue(value)}
                        />
                      </div>
                      <button id="btnLogin" type="submit" className="btn btn-primary w-100 text-white d-grid rounded-pill">
                        Request OTP
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestOtpMobileNumber;
