import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="landing-footer footer-text">
        <div className="container">
          <div className="row gx-0 gy-4 g-md-5 justify-content-center">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-6">
                  <Link to="/customer" className="app-brand-link mb-4">
                    <img src="assets/img/logo-white.png" alt="" width="100px" />
                  </Link>
                </div>
                <div className="col-lg-6">
                  <p className="footer-text mb-4">
                    Your personal portal for sending happiness effortlessly. Send happiness so that conversations never stop!
                    {/* Welcome to Shaad Online – your personal portal for sending happiness effortlessly. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom py-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-2 mb-md-0">
                  <span className="footer-text">©
	                  Shaad Online {currentYear}, all rights owned by Shaad Online L.L.C FZ
                  </span>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="Power-right mb-0">
                  <Link className="text-white" to="#">
                    Privacy Policy
                  </Link>{" "}|{" "}
                  <Link className="text-white" to="#">
                    Terms &amp; Conditions
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
